import {createPortal} from "react-dom";
import {ReadMoreContainer} from "./ReadMoreContainer";
import {ModalBackdrop} from "../modal/ModalBackdrop";

export const ReadMorePortal = ({open, details, onClose}) => {
    return (
        <div>
            {createPortal(
                <ModalBackdrop
                    show={open}
                    onClose={onClose}
                />,
                document.getElementById("quiz-backdrop-root")
            )}
            {createPortal(
                <ReadMoreContainer open={open} details={details} onClose={onClose} />,
                document.getElementById("quiz-overlay-root")
            )}
        </div>
    )
}