import {QUIZ_FINISHED, QUIZ_RESET, QUIZ_STARTED, useQuizDispatch, useQuizState} from "./quizContext";

export const useView = () => {
    const {view} = useQuizState()
    const dispatch = useQuizDispatch()

    const resetQuiz = () => {
        dispatch({type: QUIZ_RESET})
    }

    const startQuiz = () => {
        dispatch({type: QUIZ_STARTED})
    }

    const finishQuiz = () => {
        dispatch({type: QUIZ_FINISHED})
    }

    return {
        view: view,
        landingPage: view === "landing",
        quizPage: view === "quiz",
        resultsPage: view === "result",
        resetQuiz: resetQuiz,
        startQuiz: startQuiz,
        finishQuiz: finishQuiz
    }
}