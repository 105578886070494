import {ResultContainer} from "./ResultContainer";
import {ResultKicker} from "./ResultKicker";
import {MainMatchCard} from "./MainMatchCard";
import {ResultPageContainer} from "./ResultPageContainer";
import {useQuizState} from "../quiz/quizContext";
import {MatchImage} from "./MatchImage";
import {MatchContent} from "./MatchContent";
import {LesserMatch} from "./LesserMatch";
import {LesserMatchContent} from "./LesserMatchContent";
import {Typography} from "../typography/Typography";
import {MatchBar} from "./MatchBar";
import {useAppTranslation} from "../app/appContext";

const euclideanDistance = (a, b) => Math.sqrt(Math.pow(a-b, 2))

const averageDistance = distances => distances.reduce((a, b) => a + b, 0) / distances.length
const matchPercent = avgDistance => (4 - avgDistance) / 4 * 100

export const ResultsPage = () => {
    const t = useAppTranslation()
    const {quiz} = useQuizState()
    const answersIds = quiz.questions.map(question => question.selectedAnswerUniqueId)

    const profileWithMatch = quiz.profiles.map(profile => ({
        ...profile,
        percentage: matchPercent(
            averageDistance(
                profile.responses.map(response => euclideanDistance(
                        response.likertScore,
                        quiz.questions.find(question => question.uniqueId === response.questionId)
                            .answers.find(answer => answersIds.includes(answer.uniqueId)).likertScore
                    )
                )
            )
        )
    })).sort((a, b) => b.percentage - a.percentage)

    const [bestMatch, ...rest] = profileWithMatch

    return (
        <ResultPageContainer>
            <ResultContainer>
                <ResultKicker
                    text={quiz.spectrum?.resultPrefix || t('bestMatchTitle', "Du er nærmest...")}
                />
                <MainMatchCard
                    title={bestMatch.title}
                >
                    <MatchImage
                        media={bestMatch.media}
                    />
                    <Typography
                        tag={"h2"}
                        size={"xl"}
                    >
                        {Math.round(bestMatch.percentage)}% {t('match', "match")}
                    </Typography>
                    <MatchBar percentage={`${Math.round(bestMatch.percentage)}%`}/>
                    <MatchContent text={bestMatch.details} />
                </MainMatchCard>
                <ResultKicker
                    text={t('lesserMatchTitle', "Slik matcher du med andre profiler:")}
                />
                {rest.map(profile => (
                    <LesserMatch
                        key={profile.uniqueId}
                    >
                        <MatchImage
                            media={profile.media}
                        />
                        <LesserMatchContent
                            title={profile.title}
                            text={profile.details}
                            percentageMatch={profile.percentage}
                        />
                    </LesserMatch>
                ))}
            </ResultContainer>
        </ResultPageContainer>
    )
}