import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({colors, responsiveFontSize, responsiveLineHeight}) => ({
    typography: ({fontSize, margin, opacity, color}) => ({
        fontSize:  responsiveFontSize[fontSize],
        lineHeight:responsiveLineHeight[fontSize],
        margin: margin,
        padding: 0,
        color: color || colors.black,
        opacity: opacity
    })
}))


export const Typography = ({
    tag: Tag = "p",
    size="standard",
    opacity = "100%",
    color,
    children
}) => {
    const classes = useStyles({
        fontSize: size,
        margin: "p" === Tag ? "1rem 0" : 0,
        opacity: opacity,
        color: color
    })
    return (
        <Tag
            className={classes.typography}
        >
            {children}
        </Tag>
    )
}