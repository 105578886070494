import {createUseStyles} from "react-jss";
import {Typography} from "../typography/Typography";

const useStyles = createUseStyles(({colors}) => ({
    matchCard: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        transition: "height 0.5s ease-in-out",
        margin: "0 1rem 48px 1rem",
        backgroundColor: colors.white,
        padding: "1.5rem",
        borderRadius: "1.25rem",
        boxShadow: "10px 10px 10px #00000010",
        "@media (min-width: 960px)": {
            padding: "1rem 2rem 2rem 2rem"
        },
        alignItems: "center",
        gap: "16px"
    }
}))
export const MainMatchCard = ({title, children}) => {
    const classes = useStyles()
    return (
        <div className={classes.matchCard}>
            <Typography
                tag={"h1"}
                size={"xl2"}
            >
                {title}
            </Typography>
            {children}
        </div>
    )
}