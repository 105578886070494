import React from "react";
import ReactDOM from "react-dom";
import {App} from "./app/App";
import "./app/i18n";

import "./index.css";
import {createRoot} from "react-dom/client";
import {Demo} from "./demo/Demo";
import {QuizProvider} from "./quiz/quizContext";


window.kitQuiz = {
    renderDemo: args => {
        const container = document.getElementById(args.containerId)
        const root = createRoot(container)
        root.render(
            <Demo {...args} />
        )
    },
    renderQuiz: args => {
        const callback = args.onRender ? args.onRender : () => null
        const {data, onSuccess, onClose} = args
        ReactDOM.render(
            (
                <QuizProvider
                    quiz={data.quiz}
                    onSuccess={onSuccess}
                    onClose={onClose}
                >
                    <App
                        startIndex={data.startIndex}
                    />
                </QuizProvider>
            ),
            document.getElementById(args.containerId),
            callback
        )
    },

    unmountQuiz: containerId => {
        const container = document.getElementById(containerId)
        if (container) {
            ReactDOM.unmountComponentAtNode(container)
        }
    }
};

// reportWebVitals();


