import {createContext, useContext, useReducer} from "react";

const QuizStateContext = createContext(undefined);
const QuizDispatchContext = createContext(undefined);

export const NAVIGATED_FORWARD = 'navigatedForward'
export const NAVIGATED_BACKWARD = 'navigatedBackward'
export const NAVIGATED_TO_INDEX = 'navigatedToIndex'
export const ANSWER_SELECTED = 'answerSelected'
export const QUIZ_RESET = 'quizReset'
export const QUIZ_STARTED = 'quizStarted'
export const QUIZ_FINISHED = 'quizFinished'
export const STARTINDEX_CHANGED = 'startIndexChanged'


const initialState = {
    quiz: {},
    questionIndex: 0,
    view: "landing",
    onSuccess: null,
    onClose: null
}

const quizReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case QUIZ_STARTED:
            return {
                ...state,
                view: "quiz"
            }
        case QUIZ_FINISHED:
            return {
                ...state,
                view: "result"
            }
        case NAVIGATED_FORWARD:
            return {
                ...state,
                questionIndex: state.questionIndex + 1
            }
        case NAVIGATED_BACKWARD:
            return {
                ...state,
                questionIndex: state.questionIndex - 1
            }
        case NAVIGATED_TO_INDEX:
            return {
                ...state,
                questionIndex: action.questionIndex
            }
        case ANSWER_SELECTED:
            return {
                ...state,
                quiz: {
                    ...state.quiz,
                    questions: [
                        ...state.quiz.questions.filter(question => question.uniqueId !== action.questionUniqueId),
                        {
                            ...state.quiz.questions.find(question => question.uniqueId === action.questionUniqueId),
                            selectedAnswerUniqueId: action.selectedAnswerUniqueId,
                        }
                    ]
                },
            }
        case QUIZ_RESET:
            return {
                ...state,
                quiz: {
                    ...state.quiz,
                    questions: state.quiz.questions.map(question => ({
                        ...question,
                        selectedAnswerUniqueId: undefined
                    })),
                },
                questionIndex: 0,
                view: "landing"
            }
        case STARTINDEX_CHANGED:
            return {
                ...state,
                questionIndex: action.startIndex,
                view: "quiz"
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`);

    }
}

export const QuizProvider = ({quiz, onSuccess, onClose, children}) => {
    const [state, dispatch] = useReducer(
        quizReducer,
        {
            ...initialState,
            quiz: quiz,
            onSuccess: onSuccess,
            onClose: onClose
        },
        undefined
    )

    return (
        <QuizStateContext.Provider value={state}>
            <QuizDispatchContext.Provider value={dispatch}>
                {children}
            </QuizDispatchContext.Provider>
        </QuizStateContext.Provider>
    )
}

export const useQuizState = () => {
    const context = useContext(QuizStateContext)
    if (undefined === context) {
        throw new Error("useQuizState must be used within a QuizProvider")
    } else {
        return context
    }
}

export const useQuizDispatch = () => {
    const context = useContext(QuizDispatchContext)
    if (undefined === context) {
        throw new Error("useQuizDispatch must be used within a QuizProvider")
    } else {
        return context
    }
}