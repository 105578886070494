import {ArrowLeft} from "lucide-react";
import {createUseStyles} from "react-jss";
import {useNavigation} from "../navigation/useNavigation";
import {useAppTranslation} from "../app/appContext";

const useStyles = createUseStyles( ({colors}) => ({
    previousButton: {
        fontSize: ".875rem",
        display: "flex",
        width: "fit-content",
        backgroundColor: "unset",
        border: "unset",
        borderRadius: ".25rem",
        alignItems: "center",
        gap: "0.25rem",
        color: colors.shyDark,
        cursor: "pointer",
        padding: 0,
        "&:hover": {
            filter: "brightness(120%)"
        },
        "&:focus": {
            boxShadow: `0 0 0 3px ${colors.primary}`,
            outline: "none"
        },
    },
}))
export const PreviousButton = () => {
    const classes = useStyles()
    const {
        navigateBackward,
        firstQuestion
    } = useNavigation()
    const t = useAppTranslation()
    return (
        <button
            className={classes.previousButton}
            onClick={navigateBackward}
            disabled={firstQuestion}
        >
            <ArrowLeft/>
            {t('previousButton', "Forrige")}
        </button>
    )
}