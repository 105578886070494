import {useCurrentQuestion} from "../question/useCurrentQuestion";
import {Typography} from "../typography/Typography";
import {OpinionCard} from "./OpinionCard";
import {Progress} from "./Progress";
import {OpinionAnswers} from "./OpinionAnswers";
import {QuizContentContainer} from "../quiz/QuizContentContainer";
import {OpinionNavigation} from "./OpinionNavigation";
import {PreviousButton} from "./PreviousButton";
import {ContinueButton} from "./ContinueButton";
import {ReadMoreButton} from "./ReadMoreButton";
import {MatchImage} from "../spectrum-result/MatchImage";

export const Opinion = () => {
    const question = useCurrentQuestion()

    return (
        <QuizContentContainer>
            <OpinionCard>
                <Progress/>
                <MatchImage
                    media={question.breadcrumbMedia}
                    maxHeight={"300px"}
                    blockMargin={true}
                />
                <Typography
                    tag={"h2"}
                    size={"xl2"}
                >
                    {question.content}
                </Typography>

                <ReadMoreButton
                    details={question.details}
                />
                <OpinionAnswers
                    question={question}
                />
                <OpinionNavigation>
                    <PreviousButton />
                    <ContinueButton />
                </OpinionNavigation>
            </OpinionCard>
        </QuizContentContainer>
    )
}