import {ChevronRight} from "react-feather";
import {Button} from "../common/Button";
import React from "react";
import {useNavigation} from "../navigation/useNavigation";
import {useAppTranslation} from "../app/appContext";
import {createUseStyles} from "react-jss";
import {useCurrentAnswer} from "../answer/useCurrentAnswer";
import {useView} from "../quiz/useView";

const useStyles = createUseStyles(() => ({
    nextButton: {
        display: "flex",
        padding: 4,
        cursor: "pointer",
    },
}))

export const NextButton = ({isMobile}) => {
    const t = useAppTranslation()
    const {
        navigateForward,
        lastQuestion
    } = useNavigation()

    const {finishQuiz} = useView()

    const {
        answerUniqueId
    } = useCurrentAnswer()

    const classes = useStyles()

    const onForward = () => {
        if(lastQuestion) {
            finishQuiz()
        } else {
            navigateForward()
        }
    }

    const getLabel = () => {
        if(isMobile) {
            return ""
        } else {
            return lastQuestion ? t("completeButton", "Fullfør") : t("nextButton", "Neste")
        }
    }

    if(answerUniqueId) {
        return (
            <Button
                className={classes.nextButton}
                onClick={onForward}
                Icon={<ChevronRight />}
                iconRight
                outline
                label={getLabel()}
            />
        )
    } else {
        return null
    }
}