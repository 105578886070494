import {createUseStyles} from "react-jss";
import {useTransition, animated} from "@react-spring/web";

const useStyles = createUseStyles({
    backdrop: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        top: "-60px",
        bottom: "-60px",
        right: 0,
        left: 0,
        position: "fixed",
    }
})
export const ModalBackdrop = ({show, onClose}) => {
    const classes = useStyles()

    const transitions = useTransition(
        show,
        {
            from: {
                opacity: 0,
            },
            enter: {
                opacity: 1,
            },
            leave: {
                opacity: 0,
            }
        }
    )


    return transitions((style, item) => {
        if (item) {
            return (
                <animated.div
                    style={style}
                    className={classes.backdrop}
                    onClick={onClose}
                >

                </animated.div>
            )
        }

    })
}