import {BadgeHelp} from 'lucide-react'
import {createUseStyles} from "react-jss";
import {useState} from "react";
import {ReadMorePortal} from "./ReadMorePortal";
import {useAppTranslation} from "../app/appContext";
import {useQuizState} from "../quiz/quizContext";

const useStyles = createUseStyles(({colors}) => ({
    readMoreButton: {
        fontSize: ".875rem",
        display: "flex",
        width: "fit-content",
        backgroundColor: "unset",
        border: "unset",
        borderRadius: ".25rem",
        alignItems: "center",
        gap: "0.25rem",
        color: colors.shyDark,
        cursor: "pointer",
        padding: 0,
        marginTop: "5px",
        "&:hover": {
            filter: "brightness(120%)"
        },
        "&:focus": {
            boxShadow: `0 0 0 3px ${colors.primary}`,
            outline: "none"
        },
    },
}))
export const ReadMoreButton = ({details}) => {
    const classes = useStyles()
    const [portalOpen, setPortalOpen] = useState(false)
    const t = useAppTranslation()
    const {quiz} = useQuizState()

    const handleOpenPortal = () => setPortalOpen(true)

    const handleClosePortal = () => setPortalOpen(false)

    if (details) {
        return (
            <>
                <button
                    className={classes.readMoreButton}
                    onClick={handleOpenPortal}
                >
                    <BadgeHelp
                        size={"20px"}
                    />
                    {quiz.spectrum?.moreInfoText || t('readMore', "Les mer")}
                </button>
                <ReadMorePortal
                    details={details}
                    open={portalOpen}
                    onClose={handleClosePortal}
                />
            </>
        )
    } else {
        return null
    }
}