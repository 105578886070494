import React from "react";
import {createUseStyles} from "react-jss";
import {BackButton} from "../question/BackButton";
import {NextButton} from "../question/NextButton";
import {Dots} from "../navigation/Dots";
import {useNavigation} from "../navigation/useNavigation";
import {useCurrentAnswer} from "../answer/useCurrentAnswer";

const useStyles = createUseStyles(() => ({
    steps: {
        display: "flex",
        flexDirection: "row",
        justifyContent: ({ hasButtons }) =>
            hasButtons ? "space-between" : "center",
        alignItems: "center",
        maxWidth: "1200px",
        width: "100%",
        "@media (max-width: 375px)": {
            width: "90%",
        },
    },
    buttonWrapper: {
        width: "146px",
        height: "60px",
        "@media (max-width: 375px)": {
            width: "48px",
            height: "48px",
        },
    },
}));

export const Steps = () => {
    const {questionIndex} = useNavigation()
    const {
        answerUniqueId
    } = useCurrentAnswer()

    const classes = useStyles({
        hasButtons:
            questionIndex > 0 || answerUniqueId,
    });

    const isMobile = window.matchMedia("(max-width: 375px)").matches;

    return (
        <div className={classes.steps}>
            <div className={classes.buttonWrapper}>
                <BackButton
                   isMobile={isMobile}
                />
            </div>
            <Dots />
            <div className={classes.buttonWrapper}>
                <NextButton
                    isMobile={isMobile}
                />
            </div>
        </div>
    );
};
