import cx from "classnames";
import {Check, X} from "react-feather";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(() => ({
    resultQuestionAlternative: {
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        padding: 10,
        "&.yourAnswer": {
            border: "4px solid #4485c1",
        },
    },
}))

export const Answer = ({answer, idx, yourAnswer}) => {
    const classes = useStyles();
    const alternatives = ["a", "b", "c", "d"]

    return (
        <span
            className={cx(
                classes.resultQuestionAlternative,
                {
                    yourAnswer: answer.uniqueId === yourAnswer.uniqueId,
                }
            )}
        >
            {`${alternatives[idx].toUpperCase()}: ${
                answer.content
            }`}
            {answer.uniqueId === yourAnswer.uniqueId && !answer.correct && (
                <X color="#c20012"/>
            )}
            {answer.correct && (
                <Check color="#619c58"/>
            )}
        </span>
    )
}