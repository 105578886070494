import {Typography} from "../typography/Typography";
import {useTheme} from "react-jss";
import {useQuestions} from "../question/useQuestions";
import {useQuizState} from "../quiz/quizContext";
import {useAppTranslation} from "../app/appContext";

export const Progress = () => {
    const {colors} = useTheme()
    const {
        questionIndex,
        quiz
    } = useQuizState()
    const questions = useQuestions()
    const t = useAppTranslation()

    const text = () => {
        if(quiz.spectrum?.progressButtonText) {
            return `${quiz.spectrum.progressButtonText} ${questionIndex + 1} av ${questions.length}`
        } else {
            return t(
                'questionProgress',
                "Påstand {{index}} av {{count}}",
                {
                    index: questionIndex + 1,
                    count: questions.length
                }
            )
        }
    }

    return (
        <Typography
            size={"sm"}
            color={colors.shyDark}
            tag={"div"}
        >
            {text()}
        </Typography>
    )
}