import { createContext, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const appReducer = (state, action) => {
    switch (action.type) {
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, {}, undefined);

    const { t, ready } = useTranslation("vm-quiz.app", {
        useSuspense: false,
    });

    if (!ready) {
        return "Loading...";
    } else {
        return (
            <AppStateContext.Provider value={state}>
                <AppDispatchContext.Provider value={dispatch}>
                    <AppTranslationContext.Provider value={t}>
                        {children}
                    </AppTranslationContext.Provider>
                </AppDispatchContext.Provider>
            </AppStateContext.Provider>
        );
    }
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
};

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
};

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider");
    } else {
        return context;
    }
};
