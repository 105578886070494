import React from "react";
import {createUseStyles} from "react-jss";
import {App} from "../app/App";
import * as spectrumDemo from './spectrum-demo.json'
import * as quizDemo from './demo.json'
import {QuizProvider} from "../quiz/quizContext";

const useStyles = createUseStyles({
    demo: {
        height: "calc(100dvh - 64px)",
        width: "100%",
    },
});

export const Demo = () => {
    const classes = useStyles();
    return (
        <div className={classes.demo}>

            <QuizProvider
                // quiz={quizDemo}
                quiz={spectrumDemo}
                onSuccess={() => {
                    console.log("tada");
                }}
            >
                <App/>
            </QuizProvider>
        </div>
    );
}


