import React from "react";
import cx from "classnames";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { Typography } from "./Typography";

const useStyles = createUseStyles(
    (theme) => ({
        button: {
            minWidth: 48,
            width: "fit-content",
            display: "flex",
            flexDirection: (props) => (props.iconRight ? "row-reverse" : "row"),
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 30,
            "&.type-normal": {
                height: 48,
                borderRadius: 999,
                color: theme.colors.black,
                backgroundColor: (props) => props.color,
                border: "none",
                padding: (props) => (props.label ? "11px 20px" : "0"),
                "&:hover": {
                    backgroundColor: "white",
                },
                "&:focus": {
                    border: "2px solid black",
                },
                "&:active": { opacity: 0.7 },
            },
            "&.type-large": {
                height: 72,
                borderRadius: 999,
                color: theme.colors.black,
                backgroundColor: (props) => props.color,
                border: "none",
                padding: (props) => (props.label ? "23px 32px" : "0"),
                "&:hover": {
                    opacity: 0.7,
                },
                "&:focus": {
                    border: "2px solid black",
                },
                "&:active": { opacity: 1.2 },
            },
            "&.outline": {
                border: `2px solid ${theme.colors.black}`,
            },
        },
        label: {
            cursor: "pointer",
            paddingLeft: ({ label, Icon }) => (label && Icon ? "9px" : 0),
        },
    }),
    { name: "vm" }
);

export const Button = (props) => {
    const { label, Icon, color, type, iconRight, className, outline, ...rest } =
        props;
    const style = useStyles(props);
    return (
        <button
            className={cx(className, style.button, `type-${type}`, {
                outline: outline,
            })}
            {...rest}
        >
            {Icon && Icon}
            {label && (
                <Typography tag="label" className={style.label} size="base">
                    {label}
                </Typography>
            )}
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.string,
    Icon: PropTypes.node,
    color: PropTypes.string,
    iconRight: PropTypes.bool,
    type: PropTypes.oneOf(["normal", "large"]),
    outline: PropTypes.bool,
    className: PropTypes.string,
};

Button.defaultProps = {
    label: "",
    type: "normal",
    iconRight: false,
    className: "",
    outline: true,
};
