import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    lesserMatch: {
        margin: "48px",
        "@media (min-width: 1024px)": {
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: "1rem",
        }
    }
})
export const LesserMatch = ({children}) => {
    const classes = useStyles()
    return (
        <div
            className={classes.lesserMatch}
        >
            {children}
        </div>
    )
}