import {useView} from "../quiz/useView";
import {FrontPage} from "../frontpage/FrontPage";
import {SpectrumBackground} from "./SpectrumBackground";
import {QuizPageContainer} from "../quiz/QuizPageContainer";
import {Opinion} from "./Opionion";
import {ResultsPage} from "../spectrum-result/ResultsPage";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    quiz: {
        height: "calc(100dvh - 64px)",
        "--text-xs": "10px",
        "--text-sm": "12px",
        "--text-standard": "16px",
        "--text-lg": "18px",
        "--text-xl": "20px",
        "--text-2xl": "24px",
        "--text-3xl": "28px",
        "--text-4xl": "30px",
        "--text-5xl": "32px",
        "--text-6xl": "38px",
        "--text-7xl": "48px",
        "--text-8xl": "64px",
        "--text-9xl": "80px",
        "@media (min-width: 768px)": {
            "--text-xs": "12px",
            "--text-sm": "14px",
            "--text-lg": "20px",
            "--text-xl": "22px",
            "--text-4xl": "32px",
            "--text-5xl": "38px",
            "--text-6xl": "44px",
            "--text-7xl": "56px",
            "--text-8xl": "72px",
            "--text-9xl": "96px",
        },
        "@media (min-width: 1280px)": {
            "--text-3xl": "30px",
            "--text-4xl": "36px",
            "--text-5xl": "40px",
            "--text-6xl": "56px",
            "--text-7xl": "64px",
            "--text-8xl": "80px",
            "--text-9xl": "112px",
        },
        "@media (min-width: 1536px)": {
            "--text-4xl": "38px",
            "--text-5xl": "48px",
            "--text-6xl": "60px",
            "--text-7xl": "72px",
            "--text-8xl": "96px",
            "--text-9xl": "128px",
        },
    }
})
export const SpectrumQuiz = () => {
    const classes = useStyles()
    const {
        view,
    } = useView()

    const page = {
        'landing': (
            <>
                <SpectrumBackground/>
                <QuizPageContainer>
                    <FrontPage/>
                </QuizPageContainer>
            </>
        ),
        "quiz": (
            <>
                <SpectrumBackground/>
                <QuizPageContainer>
                    <Opinion/>
                </QuizPageContainer>
            </>
        ),
        "result": (
            <ResultsPage />
        )
    }

    return (
        <div className={classes.quiz}>
            {page[view]}
        </div>
    )

}