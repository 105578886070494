import {WelcomeCard} from "./WelcomeCard";
import {useQuizState} from "../quiz/quizContext";
import {Typography} from "../typography/Typography";
import {ArrowRight} from "react-feather";
import {Button} from "../common/Button";
import React from "react";
import {useView} from "../quiz/useView";
import {Badge} from "./Badge";
import {QuizWelcomeContainer} from "./QuizWelcomeContainer";
import {useAppTranslation} from "../app/appContext";

export const FrontPage = () => {
    const {quiz} = useQuizState()
    const {
        startQuiz
    } = useView()
    const t = useAppTranslation()

    return (
        <QuizWelcomeContainer>
            <WelcomeCard>
                <Badge />
                <Typography
                    sx={"sm"}
                    opacity={"70%"}
                >
                    {t('spectrumSubTitle', 'Test deg selv')}
                </Typography>
                <Typography
                    tag="h1"
                    size={"xl4"}
                >
                    {quiz.title || quiz.name}
                </Typography>
                <Typography>
                    {quiz.ingress}
                </Typography>
                <Button
                    Icon={<ArrowRight />}
                    iconRight
                    label={quiz.spectrum?.startButtonText || t("startSpectrumButton", "Start testen")}
                    outline
                    onClick={startQuiz}
                />
            </WelcomeCard>
        </QuizWelcomeContainer>
    )
}