import {NAVIGATED_BACKWARD, NAVIGATED_FORWARD, NAVIGATED_TO_INDEX, useQuizDispatch, useQuizState} from "../quiz/quizContext";
import {useQuestions} from "../question/useQuestions";

export const useNavigation = () => {
    const {questionIndex} = useQuizState()
    const questions = useQuestions()
    const dispatch = useQuizDispatch()

    const navigateForward = () => {
        dispatch({type: NAVIGATED_FORWARD})
    }

    const navigateBackward = () => {
        dispatch({type: NAVIGATED_BACKWARD})
    }

    const navigateToIndex = (newQuestionIndex) => {
        dispatch({
            type: NAVIGATED_TO_INDEX,
            questionIndex: newQuestionIndex
        })
    }

    return {
        navigateForward,
        navigateBackward,
        navigateToIndex,
        questionIndex,
        lastQuestion: questionIndex === questions.length - 1,
        firstQuestion: questionIndex === 0
    }
}