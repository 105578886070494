import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    resultPageContainer: {
        height: "100%",
        width: "100%",
        position: "relative",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "rgb(239, 240, 240)"
    }
})
export const ResultPageContainer = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.resultPageContainer}>
            {children}
        </div>
    )
}