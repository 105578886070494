import {createUseStyles} from "react-jss";
import {useCurrentAnswer} from "../answer/useCurrentAnswer";

const useStyles = createUseStyles(({colors}) => ({
    opinionAnswer: {
        margin: 0,
        textIndent: 0,
        listStyleType: "none",
        textAlign: "left",
        marginBottom: ".5rem",
        cursor: "pointer",
        padding: ".75rem",
        border: `1.5px solid ${colors.border}`,
        borderRadius: ".5rem",
        '&:hover': {
            border: `1.5px solid ${colors.primaryContrast}`
        },
        "&:focus": {
            boxShadow: `0 0 0 3px ${colors.primary}`,
            outline: "none",
            "&:not(:focus-visible)": {
                outline: "none",
                boxShadow: "none"
            }
        },

    },
    selected: {
        backgroundColor: colors.primary,
        color: colors.primaryContrast,
        fontWeight: 700,
        border: `1.5px solid ${colors.primaryContrast}`,
    }
}))

export const OpinionAnswer = ({answer}) => {
    const {
        answerUniqueId,
        selectAnswer
    } = useCurrentAnswer()
    const selected = answerUniqueId === answer.uniqueId
    const classes = useStyles()

    const handleClick = () => selectAnswer(answer.uniqueId)

    return (
        <li
            className={`${classes.opinionAnswer} ${selected ? classes.selected : ""}`}
            onClick={handleClick}
        >
            {answer.content}
        </li>
    )
}
