import {useQuizState} from "../quiz/quizContext";

export const useQuestions = () => {
    const {quiz} = useQuizState()

    return quiz.questions
        .filter(question => question.active)
        .sort((a, b) => a.order - b.order)
        .map(question => ({
            ...question,
            answers: question.answers
                .filter(a => a.active)
                .sort((a, b) => a.order - b.order)
        }))
}