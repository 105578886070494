import {STARTINDEX_CHANGED, useQuizDispatch} from "./quizContext";
import {useEffect} from "react";

export const useStartIndex = startIndex => {
    const dispatch = useQuizDispatch()

    useEffect(
        () => {
            if(startIndex !== null) {
                dispatch({
                    type: STARTINDEX_CHANGED,
                    startIndex: startIndex,
                })
            }
        }, [startIndex]
    )
}