export const colors = {
    black: "#0C0B0F",
    white: "#FFFFFF",
    shyDark: "#676767",
    border: "#C0C0C0",
    primary: "rgb(0, 224, 184)",
    primaryContrast: "#212121",

    optionA: "#9E204D",
    optionB: "#BB6C1B",
    optionC: "#1F619E",
    optionD: "#42AA76",

    satinLinen: "#F0EDE6",
};

export const fontSize = {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem",
    xl5: "3rem",
    xl6: "3.75rem",
    xl7: "4.5rem",
    xl8: "6rem",
};

const responsiveFontSize = {
    "xs": "var(--text-xs)",
    "sm": "var(--text-sm)",
    "standard": "var(--text-standard)",
    "lg": "var(--text-lg)",
    "xl": "var(--text-xl)",
    "xl2": "var(--text-2xl)",
    "xl3": "var(--text-3xl)",
    "xl4": "var(--text-4xl)",
    "xl5": "var(--text-5xl)",
    "xl6": "var(--text-6xl)",
    "xl7": "var(--text-7xl)",
    "xl8": "var(--text-8xl)",
    "xl9": "var(--text-9xl)",
    "base": "var(--text-standard)"
}

const responsiveLineHeight = {
    "xs": 1.5,
    "sm": 1.5,
    "base": 1.5,
    "standard": 1.5,
    "lg": 1.5,
    "xl": 1.5,
    "xl2": 1.5,
    "xl3": 1.2,
    "xl4": 1.2,
    "xl5": 1.2,
    "xl6": 1.2,
    "xl7": 1.2,
    "xl8": 1.2,
    "xl9": 1.2,
}

export const lineHeight = {
    leadingNone: 1,
    leadingTight: 1.25,
    leadingSnug: 1.375,
    leadingNormal: 1.5,
    leadingRelaxed: 1.6,
    leadingLoose: 2,
    leadingXloose: 2.25,
    leadingXxloose: 2.5,
};

export const theme = {
    colors,
    fontSize,
    lineHeight,
    responsiveFontSize: responsiveFontSize,
    responsiveLineHeight: responsiveLineHeight
};
