import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    resultContainer: {
        maxWidth: "40rem",
        width: "100%"
    }
})
export const ResultContainer = ({children}) => {
    const classes = useStyles()

    return (
        <div className={classes.resultContainer}>
            {children}
        </div>
    )
}