import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({colors}) => ({
    opinionCard: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        transition: "height 0.5s ease-in-out",
        margin: "0 1rem 1rem 1rem",
        backgroundColor: colors.white,
        padding: "1.5rem",
        borderRadius: "1.25rem",
        boxShadow: "10px 10px 10px #00000010"
    }
}))
export const OpinionCard = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.opinionCard}>
            {children}
        </div>
    )
}