import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({colors}) => ({
    welcomeCard: {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        transition: "height .5s ease-in-out",
        margin: "0 1rem 1rem 1rem",
        backgroundColor: colors.white,
        padding: "1.5rem",
        paddingTop: "80px",
        paddingLeft: "48px",
        paddingRight: "48px",
        borderRadius: "48px",
        boxShadow: "10px 10px 10px #00000010",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        gap: "8px"
    }
}))
export const WelcomeCard = ({children}) => {
    const classes = useStyles()
    return (
        <div
            className={classes.welcomeCard}
        >
            {children}
        </div>
    )
}