import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    quizPageContainer: {
        display: "flex",
        width: "100%",
        height: "calc(100dvh - 64px)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        position: "fixed",
        overflowX: "hidden",
        overflowY: "auto",
        "@media (min-width: 1024px)": {
            justifyContent: "center"
        },
    }
})
export const QuizPageContainer = ({children}) => {
    const classes = useStyles()
    return (
        <div
            className={classes.quizPageContainer}
        >
            {children}
        </div>
    )
}