import {useQuizState} from "../quiz/quizContext";
import {useQuestions} from "./useQuestions";

export const useCurrentQuestion = () => {
    const {
        questionIndex
    } = useQuizState()

    const questions = useQuestions()

    return questions[questionIndex]
}