import React, {useState} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    img: {
        objectFit: "cover",
        width: "100%",
        height: "calc(100% - 64px)",
        position: "absolute",
        objectPosition: ({objectPosition}) => objectPosition,
    },
});

export const BackgroundImage = ({media, positionX=50, positionY=50}) => {
    const [error, setError] = useState(false)
    const classes = useStyles({
        objectPosition: `${positionX}% ${positionY}%`,
    })

    const handleImageError = () => {
        setError(true)
    }

    if(media.dmsId) {
        if(error) {
            return (
                <picture>
                    <img
                        className={classes.img}
                        src={`https://ems.dimu.org/image/${media.dmsId}?dimension=max`}
                        alt=""
                    />
                </picture>
            )
        } else {
            return (
                <picture>
                    <source
                        srcSet={`https://ems.dimu.org/image/${media.dmsId}?dimension=3072 3072w, https://ems.dimu.org/image/${media.dmsId}?dimension=2048x2048 2048w, https://ems.dimu.org/image/${media.dmsId}?dimension=1024x1024 1024w`}
                        type="image/webp"
                    />
                    <img
                        className={classes.img}
                        src={`https://ems.dimu.org/image/${media.dmsId}?dimension=1200x1200`}
                        alt=""
                        onError={handleImageError}
                    />
                </picture>
            )
        }

    } else {
        return null
    }

}