import {Typography} from "../typography/Typography";
import {useAppTranslation} from "../app/appContext";
import draftToHtml from "draftjs-to-html";

export const LesserMatchContent = ({title, text, percentageMatch}) => {
    const t = useAppTranslation()
    return (
        <div>
            <Typography
                tag={"h2"}
                size={"xl"}
            >
                {title}
            </Typography>
            <Typography
                tag={"h3"}
                size={"lg"}
            >
                {Math.round(percentageMatch)}% {t('match', "match")}
            </Typography>
            <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(text))}} />
        </div>
    )
}