import {createUseStyles, useTheme} from "react-jss";
import {User} from 'lucide-react'

const useStyle = createUseStyles({
    badge: {
        position: "absolute",
        top: "-50px"
    },
    icon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: 1,
        transform: "translateX(-50%) translateY(-50%)",
    }
})
export const Badge = () => {
    const classes = useStyle()
    const {colors} = useTheme()
    return (
        <div
            className={classes.badge}
        >
            <div
                className={classes.icon}
            >
                <User
                    color={"white"}
                    size={"40"}
                />
            </div>

            <svg
                fill={colors.primary}
                height="135px"
                width="135px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-5.54 -5.54 195.83 195.83"
                transform="rotate(90)"
                stroke="#000000"
                strokeWidth="0.0018475100000000001"
            >
                <g
                    id="SVGRepo_bgCarrier"
                    strokeWidth="0"
                >

                </g>
                <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#f5f5f5"
                    strokeWidth="11.08506"
                >
                    <path d="M0,92.375l46.188-80h92.378l46.185,80l-46.185,80H46.188L0,92.375z"></path>
                </g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M0,92.375l46.188-80h92.378l46.185,80l-46.185,80H46.188L0,92.375z"></path>
                </g>
            </svg>
        </div>
    )
}