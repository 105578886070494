import {OpinionAnswer} from './OpinionAnswer'
import {createUseStyles} from "react-jss";
const useStyles = createUseStyles({
    opinionAnswers: {
        margin: 0,
        padding: 0,
        textIndent: 0,
        listStyleType: "none",
        textAlign: "left",
        marginBottom: ".5rem",
        marginTop: "0.5rem"
    }
})

export const OpinionAnswers = ({question}) => {
    const classes = useStyles()

    return (
        <ul
            className={classes.opinionAnswers}
        >
            {question.answers.map(answer => (
                <OpinionAnswer
                    key={answer.uniqueId}
                    answer={answer}
                />
            ))}
        </ul>
    )
}