import React from "react";
import {createUseStyles} from "react-jss";
import {useQuestions} from "../question/useQuestions";
import {ResultHeader} from "./ResultHeader";
import {Question} from "./Question";
import {useQuizState} from "../quiz/quizContext";

const useStyles = createUseStyles(() => ({
    resultsPage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    results: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "1200px",
        marginTop: "110px",
        "@media (max-width: 1200px)": {
            justifyContent: "center",
        },
        "@media (max-width: 576px)": {
            width: "90%",
        },
    },
}))

export const Results = () => {
    const classes = useStyles();
    const questions = useQuestions()
    const {
        onSuccess: onAllAnswersCorrect
    } = useQuizState()

    const selectedAnswers = questions.map(question => question.answers.find(answer => answer.uniqueId === question.selectedAnswerUniqueId))
    const score = selectedAnswers.filter(answer => answer.correct).length

    if(score === questions.length) {
        onAllAnswersCorrect()
    }

    return (
        <div className={classes.resultsPage}>
            <ResultHeader
                score={score}
            />
            <div className={classes.results}>
                {questions.map((q) => (
                    <Question
                        key={q.uniqueId}
                        question={q}
                    />
                ))}
            </div>
        </div>
    );
};
