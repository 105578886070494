import {getDmsUrl} from "@ekultur/dms-url-generator";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ fontSize, lineHeight }) => ({
    media: {
        flexGrow: 1,
        minHeight: 0
    },
    image: {
        objectFit: "fill",
        width: "100%",
        height: "100%"
    }
}))

export const QuestionMedia = ({media}) => {
    const classes = useStyles()

    if(media) {
        return (
            <div className={classes.media}>
                <img
                    src={getDmsUrl(media.dmsId)}
                    alt={media.alt}
                    className={classes.image}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
        )
    } else {
        return null
    }
}