import {Typography} from "../common/Typography";
import {Button} from "../common/Button";
import React from "react";
import {useQuizMeta} from "../quiz/useQuizMeta";
import {useAppTranslation} from "../app/appContext";
import {useQuestions} from "../question/useQuestions";
import {createUseStyles} from "react-jss";
import {useView} from "../quiz/useView";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: "center",
    },
    score: {
        textAlign: "center",
    },
    buttons: {
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& button": {
            margin: "0 12px",
        },
    },
    button: {
        backgroundColor: theme.colors.black,
        border: `2px solid ${theme.colors.white} !important`,
        "& label": {
            color: theme.colors.white,
        },
        "&:hover": {
            backgroundColor: theme.colors.white,
            border: `2px solid ${theme.colors.black} !important`,
            "& label": {
                color: theme.colors.black,
            },
        },
    },
}))

export const ResultHeader = ({score}) => {
    const {title}= useQuizMeta()
    const classes = useStyles();
    const t = useAppTranslation();
    const questions = useQuestions()
    const {resetQuiz} = useView()


    return (
        <>
            <Typography tag="h1" size="xl5" bold className={classes.title}>
                {title}
            </Typography>
            <Typography tag="h2" size="xl7" bold className={classes.score}>
                {score} {t("of", "av")} {questions.length}{" "}
                {t("correctAnswers", "riktige svar")}
            </Typography>
            <div className={classes.buttons}>
                <Button
                    label={t("resetButton", "Ta Quizen igjen")}
                    onClick={resetQuiz}
                    outline
                    className={classes.button}
                />
                <Button
                    label={t("shareButton", "Del resultatet")}
                    onClick={resetQuiz}
                    outline
                    className={classes.button}
                />
            </div>
        </>
    )
}