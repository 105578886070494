import React from "react";
import {useQuestions} from "../question/useQuestions";
import {useCurrentQuestion} from "../question/useCurrentQuestion";
import {createUseStyles} from "react-jss";
import {useNavigation} from "./useNavigation";
import {useView} from "../quiz/useView";

const useStyles = createUseStyles((theme) => ({
    dots: {
        display: "flex",
    },
    dot: {
        backgroundColor: theme.colors.satinLinen,
        width: "24px",
        height: "24px",
        margin: "0 8px",
        borderRadius: "48px",
        opacity: 0.3,
        "&.selected": {
            opacity: 1,
        },
    },
}));

export const Dots = () => {
    const questions = useQuestions()
    const question = useCurrentQuestion()
    const classes = useStyles()
    const {navigateToIndex} = useNavigation()
    const {quizPage} = useView()

    return (
        <div className={classes.dots}>
            {questions.map((q, idx) => (
                <div
                    key={q.uniqueId}
                    className={`${classes.dot} ${
                        quizPage && q.uniqueId === question.uniqueId ? "selected" : ""
                    }`}
                    onClick={() => {
                        if(quizPage) {
                            navigateToIndex(idx)
                        }
                    }}
                />
            ))}
        </div>
    )
}