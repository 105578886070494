import {createUseStyles} from "react-jss";

const useStyles = createUseStyles( ({colors}) => ({
    opinionNavigation: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}))

export const OpinionNavigation = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.opinionNavigation}>
            {children}
        </div>
    )
}