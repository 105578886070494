import {createUseStyles} from "react-jss";
import {GripHorizontal} from "lucide-react";
import {animated, useSpring} from "@react-spring/web";
import {useDrag} from "react-use-gesture";
import {useEffect} from "react";
import draftToHtml from 'draftjs-to-html'

const useStyles = createUseStyles(({colors}) => ({
    readMoreRoot: {
        maxWidth: "34rem",
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 3,
        height: "536px",
        width: "100%",
        touchAction: "none"
    },
    readMoreHeader: {
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
        backgroundColor: colors.white,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "&:hover": {
            cursor: "ns-resize"
        }

    },
    readMoreContainer: {
        padding: "16px 24px",
        overflowY: "auto",
        height: "calc(100dvh - 64px)",
        backgroundColor: colors.white,
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    closeButton: {
        marginTop: ".25rem",
        marginBottom: "1.25rem",
        paddingInline: "4em",
        borderRadius: "10rem",
        border: "2px solid",
        padding: "0.5em 1.25em",
        fontSize: "1em",
        fontWeight: 700,
        cursor: "pointer",
        transition: "background-color 0.2s ease-out, color 0.2s ease-out",
        backgroundColor: colors.white
    }
}))
export const ReadMoreContainer = ({open, details, onClose}) => {
    const classes = useStyles()
    const [{bottom}, set] = useSpring(() => ({bottom: -536}))

    useEffect(() => {
        set({
            bottom: open ? 0 : -536
        })
    }, [open]);

    const dragBind = useDrag(({down, movement: [mx, my]}) => {
        set({
            bottom: down ? -my : (my > 250 ? -563 : 0)
        })
        if (!down && my > 250) {
            onClose()
        }
    })

    return (
        <animated.div
            style={{
                bottom: bottom
            }}
            className={classes.readMoreRoot}

        >
            <div
                {...dragBind()}
                className={classes.readMoreHeader}
            >
                <GripHorizontal
                    color={"rgba(0, 0, 0, 0.14"}
                />
            </div>
            <div
                className={classes.readMoreContainer}

            >
                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(details))}}/>
                <div className={classes.buttonContainer}>
                    <button
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        Lukk
                    </button>
                </div>

            </div>

        </animated.div>
    )

}