import {useQuizState} from "./quizContext";
import {RegularQuiz} from "./RegularQuiz";
import {SpectrumQuiz} from "../spectrum/SpectrumQuiz";


export const Quiz = () => {
    const {quiz} = useQuizState()

    const byType = () => {
        if("spectrum" === quiz.type) {
            return <SpectrumQuiz />
        } else {
            return <RegularQuiz />
        }
    }

    return byType()
};
