import {useQuizState} from "../quiz/quizContext";
import {BackgroundImage} from "../quiz/BackgroundImage";
import {useView} from "../quiz/useView";
import {useCurrentQuestion} from "../question/useCurrentQuestion";

export const SpectrumBackground = () => {
    const {quiz} = useQuizState()
    const question = useCurrentQuestion()
    const {
        view,
    } = useView()

    const media = {
        'landing': quiz.backgroundMedia,
        'quiz': question.backgroundMedia
    }

    if(media[view]) {
        return (
            <BackgroundImage
                media={media[view]}
            />
        )
    } else {
        return null
    }
}