import {useQuizState} from "../quiz/quizContext";
import {ChevronLeft} from "react-feather";
import {Button} from "../common/Button";
import React from "react";
import {useAppTranslation} from "../app/appContext";
import {createUseStyles} from "react-jss";
import {useNavigation} from "../navigation/useNavigation";

const useStyles = createUseStyles((theme) => ({
    prevButton: {
        display: "flex",
        padding: 4,
        cursor: "pointer",
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        color: `${theme.colors.white} !important`,
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            color: `${theme.colors.black} !important`,
            "& > label": {
                color: theme.colors.black,
            },
        },
        "& > label": {
            color: theme.colors.white,
        },
        "@media (max-width: 375px)": {
            backgroundColor: theme.colors.white,
            color: `${theme.colors.black} !important`,
            "&:hover": {
                color: `${theme.colors.white} !important`,
                "& > label": {
                    color: theme.colors.white,
                },
            },
        },
    },
}))

export const BackButton = ({isMobile}) => {
    const t = useAppTranslation()
    const {
        questionIndex,
    } = useQuizState()

    const {
        navigateBackward
    } = useNavigation()

    const classes = useStyles()

    if(questionIndex === 0) {
        return null
    } else {
        return (
            <Button
                tag="button"
                className={classes.prevButton}
                onClick={navigateBackward}
                Icon={<ChevronLeft />}
                label={!isMobile ? t("previousButton", "Forrige") : ""}
                outline={isMobile}
            />
        )
    }

}