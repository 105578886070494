import {createUseStyles} from "react-jss";
import {useNavigation} from "../navigation/useNavigation";
import {useView} from "../quiz/useView";
import {useCurrentAnswer} from "../answer/useCurrentAnswer";
import {useAppTranslation} from "../app/appContext";

const useStyles = createUseStyles( ({colors}) => ({
    continueButton: {
        color: colors.black,
        borderRadius: "10rem",
        border: `2px solid ${colors.black}`,
        padding: "0.5em 1.25em",
        fontSize: "1em",
        fontWeight: "700",
        cursor: "pointer",
        transition: "background-color 0.2s ease-out, color 0.2s ease-out",
        backgroundColor: colors.white,
        "&:focus": {
            boxShadow: `0 0 0 3px ${colors.primary}`,
            outline: "none"
        },
        "&:focus-visible": {
            boxShadow: `0 0 0 3px ${colors.primary}`,
            outline: "none"
        },
        "&:not([disabled]):hover": {
            backgroundColor: colors.black,
            color: colors.white
        },
        "&:not([disabled]):active": {
            borderColor:  colors.primary
        },
        "&[disabled]": {
            borderColor: colors.border,
            color: colors.border,
            backgroundcolor: colors.white
        }
    }
}))

export const ContinueButton = () => {
    const classes = useStyles()
    const t = useAppTranslation()
    const {
        navigateForward,
        lastQuestion
    } = useNavigation()

    const {finishQuiz} = useView()

    const {
        answerUniqueId
    } = useCurrentAnswer()

    const handleClick = () => {
        if(lastQuestion) {
            finishQuiz()
        } else {
            navigateForward()
        }
    }

    const buttonText = lastQuestion ? t("completeButton", "Fullfør") : t("continueButton", "Fortsett")

    return (
        <button
            disabled={!answerUniqueId}
            className={classes.continueButton}
            onClick={handleClick}
        >
            {buttonText}
        </button>
    )
}