import {Answer} from "./Answer";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(() => ({
    resultQuestion: {
        paddingBottom: 10,
        width: "100%",
        maxWidth: "576px",
    },
    resultQuestionDef: {
        paddingBottom: 20,
    },
    resultQuestionAlternatives: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
    },
}))

export const Question = ({question}) => {
    const yourAnswer = question.answers.find(a => a.uniqueId === question.selectedAnswerUniqueId)
    const classes = useStyles()

    return (
        <div key={question.uniqueId} className={classes.resultQuestion}>
            <div className={classes.resultQuestionDef}>
                {question.content}
            </div>
            <div className={classes.resultQuestionAlternatives}>
                {question.answers
                    .map((answer, idx) => (
                        <Answer
                            key={answer.uniqueId}
                            answer={answer}
                            idx={idx}
                            yourAnswer={yourAnswer}
                        />
                    ))}
            </div>
        </div>
    )
}
