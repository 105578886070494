import {createUseStyles} from "react-jss";

const useStyles = createUseStyles( ({colors}) => ({
    matchBarContainer: {
        width: "80%",
        margin: "0 auto 1rem",
        backgroundColor: "#dadada",
        borderRadius: "4rem",
        height: "0.875rem",
        boxShadow: "inset 2px 1px 1px 1px #00000012",
    },
    matchBar: ({width}) => ({
        width: width,
        height: "100%",
        backgroundColor: colors.primary,
        borderRadius: "inherit",
        transition: "width 0.3s ease-in-out",
        mixBlendMode: "multiply"
    })
}))
export const MatchBar = ({percentage}) => {
    const classes = useStyles({
        width: percentage
    })

    return (
        <div className={classes.matchBarContainer}>
            <div className={classes.matchBar} />
        </div>
    )
}