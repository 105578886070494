import {BrowserRouter as Router} from "react-router-dom";
import {Quiz} from "../quiz/Quiz";
import {theme} from "../globalJss";
import {ThemeProvider} from "react-jss";
import {AppProvider} from "./appContext";
import {useStartIndex} from "../quiz/useStartIndex";
import React from "react";
import {AppBar} from "../AppBar/AppBar";

export const App = ({startIndex = null}) => {
    useStartIndex(startIndex)
    return (
        <>
            <Router>
                <ThemeProvider theme={theme}>
                    <AppProvider>
                        <AppBar />
                        <Quiz/>
                    </AppProvider>
                </ThemeProvider>
            </Router>
            <div id={"quiz-backdrop-root"}/>
            <div id={"quiz-overlay-root"}/>
        </>
    );
};
