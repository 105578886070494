import {Typography} from "../typography/Typography";
import {createUseStyles, useTheme} from "react-jss";

const useStyles = createUseStyles({
    resultKicker: {
        textAlign: "center",
        marginBlock: "1rem"
    }
})
export const ResultKicker = ({text}) => {
    const theme = useTheme()
    const classes = useStyles()
    return (
        <div className={classes.resultKicker}>
            <Typography
                color={theme.colors.shyDark}
            >
                {text}
            </Typography>
        </div>
    )
}