import {ANSWER_SELECTED, useQuizDispatch} from "../quiz/quizContext";
import {useCurrentQuestion} from "../question/useCurrentQuestion";

export const useCurrentAnswer = () => {
    const question = useCurrentQuestion()
    const dispatch = useQuizDispatch()

    const selectAnswer = uniqueId => {
        dispatch({
            type: ANSWER_SELECTED,
            questionUniqueId: question.uniqueId,
            selectedAnswerUniqueId: uniqueId
        })
    }

    return {
        answerUniqueId: question.selectedAnswerUniqueId,
        selectAnswer: selectAnswer
    }
}