import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "../common/Typography";
import { Button } from "../common/Button";
import { ArrowRight } from "react-feather";
import { useAppTranslation } from "../app/appContext";
import {useView} from "./useView";
import {useQuizMeta} from "./useQuizMeta";

const useStyle = createUseStyles(({ fontSize, lineHeight }) => ({
    startPage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "924px",
        margin: "0 auto",
        "@media (max-width: 500px)": {
            width: "90%",
        },
    },
    title: {
        textAlign: "center",
        "@media (max-width: 375px)": {
            fontSize: fontSize.xl,
            lineHeight: lineHeight.leadingRelaxed,
        },
    },
    description: {
        maxWidth: "500px",
        margin: "15px auto 40px auto",
        textAlign: "center",
        "@media (max-width: 375px)": {
            fontSize: fontSize.base,
            lineHeight: 1.5,
        },
    },
}));

export const StartPage = () => {
    const {title, ingress}= useQuizMeta()
    const t = useAppTranslation();
    const classes = useStyle();
    const {
        startQuiz
    } = useView()

    return (
        <div className={classes.startPage}>
            <Typography tag="h1" size="xl7" bold className={classes.title}>
                {title}
            </Typography>
            <Typography tag="p" size="xl2" className={classes.description}>
                {ingress}
            </Typography>
            <Button
                Icon={<ArrowRight />}
                iconRight
                label={t("startQuizButton", "Start quizen")}
                outline
                onClick={startQuiz}
            />
        </div>
    );
};
