import {createUseStyles} from "react-jss";
import {ResponsiveImage} from "../image/ResponsiveImage";

const useStyles = createUseStyles({
    matchImage: ({maxHeight, marginBottom, marginTop}) => ({
        borderRadius: "0.25rem",
        marginBottom: marginBottom,
        marginTop: marginTop,
        display: "block",
        maxWidth: "100%",
        maxHeight: maxHeight,
        objectFit: "cover"
    })
})
export const MatchImage = ({media, blockMargin=false, maxHeight="none"}) => {
    const classes = useStyles({
        maxHeight: maxHeight,
        marginBottom: blockMargin ? "0.5rem" : "0.75rem",
        marginTop: blockMargin ? "0.5rem" : 0
    })
    if(media) {
        return (
            <ResponsiveImage
                mediaId={media?.dmsId}
                alt={"match image"}
                className={classes.matchImage}
            />
        )
    } else {
        return null
    }

}